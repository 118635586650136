/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Swiper JS */

@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import '@ionic/angular/css/ionic-swiper';

html, body { height: 100%; }
body {
  margin: 0;
  --ion-font-family: Verdana, sans-serif;
}

[centered] {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

[centeredText] {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

ion-content {
    width: 100vw;
    --overflow: hidden; // Fix the scroll-problem (whole page was scrollable)
}

ion-item item-highlight {
    --highlight-background: none;
}

.cdk-overlay-pane {
    position: absolute;
}

ion-chip {
    user-select: none;
    -moz-user-select: none;
}

ion-input[clearInput] input {
    padding-left: 30px !important;
}

.hint {
    ion-icon {
        font-size: 26px;
        margin-right: 3px;
    }
}

ion-button {
    text-transform: none;
    --font-size: 15px;
    --box-shadow: none;
    --border-radius: 10px;
}

ion-card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

ion-card:focus {
    outline: none;
}

ion-card:hover {
    cursor: pointer;
}

ion-card-title {
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: 6px;
}

ion-card-subtitle {
    font-size: 13px;
    font-weight: 500;
    text-transform: none;
}

ion-card-content {
    font-size: 13px;
}

.cdk-drag-preview {
    font-family: var(--ion-font-family);
}

/* Loading Spinner */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.spinner {
    transform: scale(1);
    background: none !important;
    background-color: none !important;
    .loading-wrapper {
        background-color: transparent;
        box-shadow: none;
    }
}
mark {
    background-color: rgba(51, 143, 255, 0.808);
    color: white;
    line-height: 1.8;
    padding: 4px;
    border-radius: 6px;
}